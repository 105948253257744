
import { Component, Vue, Mixins } from 'vue-property-decorator';
import { ResetPassword } from '@/models/auth/reset-password.class';
import { namespace } from 'vuex-class';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { UseCurrentLocaleMixin } from '@/mixins/use-current-locale.mixin';

const authModule = namespace('auth');

@Component({
  components: { ValidationObserver, ValidationProvider },
})
export default class PasswordReset extends Mixins(UseCurrentLocaleMixin) {
  createPassword: ResetPassword = new ResetPassword();

  @authModule.Action('resetPassword')
  resetPassword!: (password: ResetPassword) => Promise<boolean>;

  showPassword = false;
  showLoading = false;
  errorTranslationKey = '';

  $refs!: Vue['$refs'] & {
    observer: InstanceType<typeof ValidationObserver>;
  };

  async onSubmit() {
    this.showLoading = true;

    this.createPassword.token = this.$route.params.token;
    const user = await this.resetPassword(this.createPassword);

    if (user) {
      this.$router.push(`${this.localeBaseUrlPath}/`);
    }

    this.showLoading = false;
    this.resetForm();
  }

  resetForm() {
    this.createPassword = new ResetPassword();
    this.$refs.observer.reset();
  }
}
